<template>
  <div>
    <router-view v-show='this.$route.matched.length==3'></router-view>
    <PageHeaderLayout v-show='this.$route.matched.length==2'>
      <div v-if="userPermissions.indexOf('kol-analysis-list') !== -1" class='main-page-content'>
        <!-- 搜索-->
        <div>
        <el-form :inline='true' class='table-search' label-position='right' style='padding-top: 0;'>
          <el-form-item>
            <el-date-picker
                v-model='time_value'
                align='right'
                end-placeholder='结束月'
                range-separator='至'
                start-placeholder='开始月'
                type='monthrange'
                unlink-panels
                value-format='yyyy-MM-dd'
                @blur='handleQuery'
                @change='handleQuery'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select v-model='selectInfo.artist_id' clearable filterable placeholder='选择红人' @change='handleQuery'>
              <el-option v-for='(item,index) in kolList' :key='index' :label='item.nickname' :value='item.artist_id'>
                <span style='float: left'> {{ item.nickname }} </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <!--        <CompanySelect :multiple="false" @handleSelect='selectDept'/>-->
            <el-select v-model='selectInfo.dept_id' clearable placeholder='选择项目组' @change='handleQuery'>
              <el-option v-for='(item,index) in groupList' :key='index' :label='item?.name' :value='item?.id'>
                <span style='float: left'> {{ item?.name }} </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon='el-icon-search' type='primary' @click='handleQuery'>查询</el-button>
            <export-btn ref='refExportLink'
                        :can-export="userPermissions.indexOf('kol-analysis-export') !== -1"
                        :lintTitle="'导出'"
                        class='download'
                        @export='downLoad'/>
            <el-button type='success'
                       style="margin: 0 10px"
                       @click='routerLabor'>基础数据维护
            </el-button>
            <!--        <el-button v-if="userPermissions.indexOf('kol-analysis-setting-list') !== -1" style='margin: 0 10px'-->
            <!--                   @click="goToPage('/kolModel/artist_model')">设置红人模型-->
            <!--        </el-button>-->
            <el-popover
                v-model='visible'
                placement='right'
                title='选择同步的月份'
                trigger='click'>
              <el-date-picker
                  v-model='syncMonth'
                  placeholder='选择月'
                  style='width: 200px;'
                  type='month'
                  value-format='yyyy-MM-dd'>
              </el-date-picker>
              <div style='float: right'>
                <el-button size='mini' style='margin: 0 10px' type='text' @click='visible = false'>取消</el-button>
                <el-button size='mini' type='primary' @click='refresh'>确定</el-button>
              </div>
              <a slot='reference' title='列表同步'>
                <el-button size="mini" type="warning"> 初始化</el-button>
              </a>
            </el-popover>
          </el-form-item>
          <el-form-item style="float: right">
            <el-radio-group v-model="mod" size="mini" @input="handleRouter">
              <el-radio-button label="kol">红人列表</el-radio-button>
              <el-radio-button label="group">项目组列表</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class='default-table'>
          <el-table ref='multipleTable' v-loading='isLoading' :data='dataList'
                    :row-class-name='rowClassName'
                    :sum-text="''"
                    :summary-method='summaries'
                    border
                    highlight-current-row
                    row-key='id'
                    show-summary
                    @sort-change='changeTableSort'
                    @cell-dblclick='handelCopy' @row-contextmenu='rowContextmenu' @selection-change='handleSelectRow'>
            <el-table-column
                type='selection'
                :reserve-selection='true'
                width='50'>
            </el-table-column>
            <el-table-column align='center' label='序号' type='index' width='60'></el-table-column>
            <!--        <el-table-column align='center' label='负责人' prop="leader_nm" width='100'></el-table-column>-->
            <el-table-column align='center' label='项目组' min-width='200'
                             prop='dept_name' show-overflow-tooltip></el-table-column>
            <el-table-column align='center' label='红人昵称' min-width='150' prop='nickname'
                             show-overflow-tooltip>
              <template slot-scope='{ row }'>
                <el-link v-if="userPermissions.indexOf('kol-analysis-item-list') !== -1" size='mini' type='primary'
                         @click='detailPage(row)'>
                  <span>{{ row.nickname }} </span>
                </el-link>
                <span v-else>{{ row.nickname }}</span>
              </template>
            </el-table-column>
            <el-table-column align='center' label='合作时长' min-width='80'
                             prop='coop_duration' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column align='center' label='月份' min-width='80' prop='month' sortable='custom'>
              <template slot-scope='{row}'>
                {{ row?.month.slice(0, 7) }}
              </template>
            </el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='收入合计'
                             min-width='120' prop='income_total' show-overflow-tooltip
                             sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='商务收入' min-width='120'
                             prop='biz_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='保量收入' min-width='120'
                             prop='stage_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='直播佣金收入' min-width='130'
                             prop='live_commission_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='短视频收入' min-width='120'
                             prop='short_video_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='直播坑位费' min-width='150'
                             prop='live_position_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='商品销售利润' min-width='150'
                             prop='goods_sale_profit' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='费用合计' min-width='120'
                             prop='cost_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='网红分成' min-width='120'
                             prop='kol_divide_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='MCN推广' min-width='120'
                             prop='promotion_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='直播投放金' min-width='130'
                             prop='live_put_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='报销' min-width='120'
                             prop='expenses_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='人工成本' min-width='120'
                             prop='labor_cost' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='商务支持团队' min-width='120'
                             prop='biz_supp_team_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='返点费用' min-width='120'
                             prop='rebate_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='预计固定费用' min-width='150'
                             prop='fixed_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <!--        <el-table-column :formatter='formatNumber' align='right' label='利润' min-width='120'-->
            <!--                         prop='profit' show-overflow-tooltip sortable='custom'></el-table-column>-->
            <el-table-column :formatter='formatNumber' align='right' label='办公租金装修' min-width='140'
                             prop='office_rr_total' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='管理服务费' min-width='120'
                             prop='manage_service_fee' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='税前利润' min-width='120'
                             prop='bef_tax_profit' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='企业所得税' min-width='120'
                             prop='corp_income_tax' show-overflow-tooltip sortable='custom'></el-table-column>
            <el-table-column :formatter='formatNumber' align='right' label='净利润' min-width='120'
                             prop='net_profits' show-overflow-tooltip sortable='custom'></el-table-column>
            <!--        <el-table-column align='center' label='创建时间' max-width='100'-->
            <!--                         prop='created_at' show-overflow-tooltip></el-table-column>-->
            <!--        <el-table-column align='center' label='修改时间' max-width='100'-->
            <!--                         prop='updated_at' show-overflow-tooltip></el-table-column>-->
          </el-table>
          <br>
          <!--        分页-->
          <el-row>
            <el-col :span="2">
              <el-button type='danger' plain v-if="userPermissions.indexOf('kol-analysis-delete') !== -1"
                         :disabled='delIds.length===0' icon='el-icon-delete'
                         @click='selectDel'>批量删除
              </el-button>
            </el-col>
            <el-col :span='22' style='text-align: right'>
              <Pagination :limit.sync='pagingData.page_size'
                          style="padding: 0" :page.sync='pagingData.current_page'
                          :total='pagingData.total'
                          @pagination='getList'/>
            </el-col>
          </el-row>
        </div>
        <el-drawer
            :before-close='closeDrawer'
            :visible.sync='addDrawer'
            title='新增红人模型'>
          <div class='addNewBox'>
            <el-form ref='ruleForm' :model='addForm' :rules='rules' label-width='80px'>
              <el-form-item label='选择红人' prop='artist_id'>
                <el-select v-model='addForm.artist_id' clearable filterable
                           placeholder='选择红人' style='width: 80%'>
                  <el-option v-for='(item,index) in kolAllData' :key='index' :label='item.nickname'
                             :value='item.artist_id'>
                    <span style='float: left'> {{ item.nickname }} ({{ item?.dept?.name || '暂无' }}) </span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label='选择月份' prop='months'>
                <el-date-picker
                    v-model='addForm.months'
                    placeholder='选择一个或多个月'
                    type='months'
                    value-format='yyyy-MM'>
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                已选月份： {{
                  String(addForm.months) && String(addForm.months) != 'null' ? String(addForm.months) : '请选择新增月份'
                }}
              </el-form-item>
              <el-form-item>
                <el-button type='primary' @click="onSubmit('ruleForm')">立即创建</el-button>
                <el-button @click='addDrawer=false'>取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-drawer>
        <!--    右键菜单-修改红人考核月份-->
        <Contextmenu :menu-data='menuData' @change='changeCheck'/>
        <el-dialog
            :before-close='closeDiag'
            :visible.sync='dialogVisible'
            title='红人模型设置－分成比例、固定费用'
            width='50%'>
          <artist-model></artist-model>
        </el-dialog>
        </div>
        <!--
            <el-dialog
                :before-close='closeLeader'
                :visible.sync='leaderVisible'
                title='选择负责人'
                width='50%'>
              <select-leader @closeLeader="closeLeader" :loading.sync="selectLeaderLoading"
                             @selectLeader="onSubmitLeader"></select-leader>
            </el-dialog>
        -->

      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ArtistSearch from '@/components/artist/ArtistSearch'
import GroupSelect from '@/pages/team/components/GroupSelect'
import Contextmenu from '@/pages/kolModel/components/Contextmenu.vue'
import {mapGetters} from 'vuex'
import {numberFormat} from '@/utils/utils'
import ExportBtn from '@/components/export/ExportBtn'
// import { getToken } from '@/utils/auth'
import uploadFileList from '@/components/uploadIndex'
import ArtistModel from '@/pages/kolModel/components/ArtistModel.vue'
import SelectLeader from '@/pages/kolModel/components/selectLeader.vue'

export default {
  name: 'kolModeIndex',
  components: {
    SelectLeader,
    ArtistModel, PageHeaderLayout, ArtistSearch, GroupSelect, Contextmenu, ExportBtn, uploadFileList
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      dataList: [],
      pagingData: {
        page_size: 20,
        current_page: 1,
        total: 0
      },
      selectInfo: {},
      groupId: '',
      time_value: '',
      groupList: [],
      kolList: [],
      isLoading: false,
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      currentRow: '',
      addDrawer: false,
      kolAllData: [],
      selectData: [],
      addForm: {
        artist_id: '',
        months: []
      },
      rules: {
        artist_id: [
          { required: true, message: '请选择红人', trigger: 'blur' }
        ],
        months: [
          { required: true, message: '请选择新增月份', trigger: 'change' }
        ]
      },
      delIds: [],
      orderSort: [],
      syncMonth: '',
      visible: false,
      dialogVisible: false,
      leaderVisible: false,
      selectLeaderLoading: false,
      mod: 'kol'
    }
  },
  watch: {

    '$route.matched': {
      immediate: true,
      deep: true,
      // eslint-disable-next-line no-unused-vars
      handler(n, o) {
        if (n.length === 2) {
          this.mod = 'kol'
          this.handleQuery()
        }
      }
    },
    dataList: {
      immediate: true,
      handler() {
        setTimeout(() => {
          const tds = document.querySelectorAll('.default-table .el-table__footer-wrapper .has-gutter tr>td')
          let merge_num = 6
          tds[0].colSpan = merge_num
          tds[0].style.textAlign = 'center'
          for (let i = 1; i < merge_num; i += 1) {
            tds[i].style.display = 'none'
          }
        }, 500)
      }
    }
  },
  methods: {
    closeLeader() {
      this.leaderVisible = false
    },
    routerLabor() {
      const { href } = this.$router.resolve({
        path: '/analysis_basic/labor_cost'
      })
      window.open(href, '_blank')
    },
    routerRebate() {
      const { href } = this.$router.resolve({
        path: '/rebate'
      })
      window.open(href, '_blank')
    },

    async downLoad() {
      try {
        let isFileSaverSupported = !!new Blob
        if (isFileSaverSupported) {
          // let searchCondition = this.handleSearchCondition()
          let selectInfo = { ...this.selectInfo, is_export: true }
          if (selectInfo.order.length === 0) {
            delete selectInfo.order
          }
          let response = await this.$api.exportKolModelList(selectInfo)
          let name = `红人模型-项目组列表`
          this.$refs.refExportLink.exportSaveXlsx(response, name)
        }
      } catch (e) {
        this.$message.warning('浏览器不支持导出文件')
      }
    },
    closeDrawer() {
      this.addForm = {
        artist_id: '',
        months: []
      }
      this.addDrawer = false
    },
    summaries(param) {
      const { columns, data } = param
      const sums = []
      let fansprice, singleFans, promotion_total, incr_total, income_total, fans_total = 0
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (['incr_total', 'fans_total', 'fixed_total', 'labor_cost', 'rebate_total', 'income_total', 'ad_total', 'live_commission_total',
          'live_position_total', 'short_video_total', 'cost_total', 'kol_divide_total', 'promotion_total',
          'live_put_total', 'expenses_total', 'profit', 'biz_total', 'stage_total',
          'biz_supp_team_total', 'office_rr_total', 'manage_service_fee',
          'bef_tax_profit', 'corp_income_tax', 'net_profits'].indexOf(column.property) > -1) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          switch (column.property) {
            case 'promotion_total':
              promotion_total = sums[index]
              break
            case 'incr_total':
              incr_total = sums[index]
              break
            case 'income_total':
              income_total = sums[index]
              break
            case 'fans_total':
              fans_total = sums[index]
              break
          }
          sums[index] = numberFormat(sums[index], 2, '.', ',', 'round')
        } else if (column.property === 'fans_price') {
          if (promotion_total && incr_total != 0) {
            fansprice = promotion_total / incr_total
          }
          sums[index] = numberFormat(fansprice, 2, '.', ',', 'round')
        } else if (column.property === 'single_fan_income') {
          if (income_total && fans_total != 0) {
            singleFans = income_total / fans_total
          }
          sums[index] = numberFormat(singleFans, 2, '.', ',', 'round')
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    handleSelectRow(val) {
      this.delIds = val.map(i => {
        return i.id
      })
      this.selectData = val

    },
    selectDel() {
      if (this.delIds?.length !== 0) {
        this.$confirm(
            `确认批量删除${this.delIds.length}条数据吗？`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
          this.deleteData({ ids: this.delIds })
        })
      } else {
        this.$message({
          message: '请选择数据', type: 'info'
        })
      }
      // this.deleteData()
    },
    selectLeader() {
      if (this.delIds?.length !== 0) {
        // this.onSubmitLeader()
        this.leaderVisible = true
      } else {
        this.$message({
          message: '请选择数据', type: 'info'
        })
      }
    },
    async onSubmitLeader(leaderName) {
      this.selectLeaderLoading = true
      for (const item of this.selectData) {
        let reviseData = {
          artist_id: item.artist_id,
          month: item.month,
          leader_nm: leaderName
        }
        await this.$api.modelCreateData(reviseData)
      }
      this.leaderVisible = false
      this.selectLeaderLoading = false
      await this.getList()
      this.$message({
        message: '修改成功', type: 'success'
      })
      this.$refs.multipleTable.clearSelection()

    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addData()
        } else {
          return false
        }
      })
    },
    async addData() {
      this.addForm.months = this.addForm.months.map(m => {
        return m + '-01'
      })
      let data = await this.$api.modeladdKolData(this.addForm)
      if (data) {
        this.$message({
          message: '新增成功', type: 'success'
        })
        this.addDrawer = false
        await this.handleQuery()
      }
    },
    async addNewData() {
      this.addDrawer = true
      this.kolAllData = await this.$api.modelGetAllKolList()
    },


    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
    },
    handelCopy(row, column) {
      const oInput = document.createElement('textarea')
      oInput.value = row[column.property]
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({ message: '复制成功', type: 'success' })
      oInput.remove()
    },
    async rowContextmenu(row, column, e) {
      if (this.userPermissions.indexOf('kol-analysis-delete') !== -1) {
        this.currentRow = row
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        e.preventDefault()//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        let left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          left = maxLeft
        }
        this.openMenu(row, column, e)
      }
// this.handelCopy()
    },
    openMenu(row, column, e) {
      let index = row.index
      if (index <= this.dataList.length) {
        // if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        this.currentRow = row
        e.preventDefault()//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left
        this.menuData = {
          visible: true,
          left: e.clientX - offsetLeft,
          top: e.pageY
        }
        document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
        // }
      }
    },
    changeCheck() {
      this.$confirm(
          `确认删除【${this.currentRow.nickname}】${this.currentRow.month.slice(0, 7)}数据吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
        this.deleteData({ ids: [this.currentRow.id] })

      })
    },
    async deleteData(delDate) {
      let data = await this.$api.modelKolDel(delDate)
      if (data) {
        this.$message({
          message: '删除成功', type: 'success'
        })
        await this.getList()
      }
    },
    //右键菜单：设置是否月考核
    closeMenu() {
      // if (e.target.className.indexOf('contextmenu__item') === -1) {
      this.menuData.visible = false
      document.removeEventListener('click', this.closeMenu)
      // }
    },
    refresh() {
      // this.isLoading = true
      if (this.syncMonth) {
        this.$confirm(
            `确定同步【${this.syncMonth.slice(0, 7)}】的全部红人数据吗？`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
          this.$message({
            message: '同步中...', type: 'success'
          })
          let RefreshData = {
            date: this.syncMonth
          }
          let data = await this.$api.modelRefresh(RefreshData)
          if (data) {
            this.$message({
              message: '同步成功', type: 'success'
            })
            await this.getList()
          }
        }).catch(() => {
          this.$message({
            message: '已取消'
          })
          this.isLoading = false
        })
      } else {
        this.$message({
          message: '请选择同步日期'
        })
        this.isLoading = false

      }


    },
    async getGroup() {
      this.groupList = await this.$api.modelGetDepList(this.selectInfo)
    },
    async getKol() {
      this.kolList = await this.$api.modelGetKolList(this.selectInfo)
    },
    changeTableSort({ prop, order }) {

      // if (order) {
      //   let incloud = this.orderSort.find((i) => {
      //     return i[prop]
      //   })
      //   if (!incloud) {
      //     let newV = { [prop]: order === 'descending' ? 'desc' : 'asc' }
      //     this.orderSort.push(newV)
      //   } else {
      //     incloud[prop] = order === 'descending' ? 'desc' : 'asc'
      //   }
      // } else {
      //   let index = this.orderSort.findIndex((i) => {
      //     return i[prop]
      //   })
      //   this.orderSort.splice(index, 1)
      // }
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }

      this.orderSort = orderSort
      this.getList()
    },
    async getList() {
      this.isLoading = true
      Object.assign(this.selectInfo, {
        page_size: this.pagingData.page_size || 10,
        current_page: this.pagingData.current_page || 1
      }, { order: this.orderSort })
      const { list, page_info } = await this.$api.modelGetModelList(this.selectInfo)
      this.dataList = list
      this.pagingData = page_info
      this.isLoading = false
    },
    detailPage(row) {
      const { href } = this.$router.resolve({
        name: 'modelDetail',
        params: { id: row.artist_id },
        query: {
          year: row.month.slice(0, 4),
          month: row.month.slice(5, 7)
        }
      })
      window.open(href, '_blank')
    },
    formatNumber(row, col) {
      return this.$utils.numberFormat(row[col.property], 2, '.', ',', 'round')
    },
    async selectDept(val) {
      if (val && val.length > 0) {
        this.selectInfo.dept_id = val[0]
        this.handleQuery()
      }
      // let group_id = null
      // selectInfo.dept_id
      // this.selectInfo.group_range = val
      // this.selectInfo.dept_id = null

    },
    handleQuery() {
      if (this.time_value?.[0]) {
        this.selectInfo.begin_start = this.time_value?.[0]
        this.selectInfo.begin_end = this.time_value?.[1]
      } else {
        delete this.selectInfo.begin_start
        delete this.selectInfo.begin_end
      }

      if (this.selectInfo.dept_id === '') {
        delete this.selectInfo.dept_id
      }
      if (this.selectInfo.artist_id === '') {
        delete this.selectInfo.artist_id
      }
      this.pagingData.current_page = 1
      this.getGroup()
      this.getKol()
      this.getList()
    },
    chooseArtist(item) {
      if (item) {
        this.selectInfo.artist_id = item.id
      } else {
        this.selectInfo.artist_id = null
      }
      this.handleQuery()
    },
    goToPage() {
      this.dialogVisible = true
      // this.$router.push({ path: routeName })
    },
    closeDiag() {
      this.dialogVisible = false
    },
    handleRouter() {
      if (this.mod === 'group') {
        this.$router.push({
          name: 'modelTeam'
        })
      }
    }
  },
  mounted() {
    // this.handleQuery()

  }
}
</script>

<style lang='scss' scoped>
.addNewBox {
  width: 90%;
  margin: 0 auto;

  > * {
    padding: 10px;
  }
}


.download {
  font-size: 18px;
  margin-left: 12px;
  color: #ff3176;
  cursor: pointer;
  display: inline-grid !important;
}
</style>

